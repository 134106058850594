import FinalAccessionReportConfig from "store/models/FinalAccessionReportConfig";
import FinalAccessionReport from "store/models/FinalAccessionReport";

import StoreActions from "store/actions";

const configStoreActions = new StoreActions(FinalAccessionReportConfig);
const storeActions = new StoreActions(FinalAccessionReport);

export default class FinalAccessionReportConfigActions {
  async getMonthlyFARC(limitData = null) {
    return await configStoreActions.query(
      {
        queryName: "getMonthlyFinalAccessionReportConfig",
        relativeUrl: "/final-reported-accessions/get-monthly-farc",
        shouldNotReturnId: true,
        limitData,
      },
      "To fetch monthly final acccession report config."
    );
  }

  async getCustomFARC(limitData = null) {
    return await configStoreActions.query(
      {
        queryName: "getCustomFinalAccessionReportConfig",
        relativeUrl: "/final-reported-accessions/get-custom-farc",
        shouldNotReturnId: true,
        limitData,
      },
      "To fetch custom final acccession report config."
    );
  }

  async updateMonthlyFARC(params) {
    const { variables, limitData } = params;

    return await configStoreActions.mutation(
      {
        relativeUrl: "/final-reported-accessions/update-monthly-farc",
        queryName: "updateMonthlyFinalAccessionReportConfig",
        shouldNotReturnId: true,
        limitData: limitData || null,
      },
      "To update monthly final accession report config.",
      variables
    );
  }

  async updateCustomFARC(params) {
    const { variables, limitData } = params;

    return await configStoreActions.mutation(
      {
        relativeUrl: "/final-reported-accessions/update-custom-farc",
        queryName: "updateCustomFinalAccessionReportConfig",
        shouldNotReturnId: true,
        limitData: limitData || null,
      },
      "To update custom final accession report config.",
      variables
    );
  }

  async generateCustomFinalAccessionReport(params) {
    const { input, limitData } = params;
    return await storeActions.mutation(
      {
        relativeUrl: "/final-reported-accessions/generate-custom-far",
        queryName: "generateCustomFinalAccessionReport",
        shouldNotReturnId: true,
        limitData: limitData || null,
      },
      "To update custom final accession report config.",
      { input }
    );
  }

  async getFinalAccessionReport(params) {
    const { variables, limitData } = params;

    return await storeActions.query(
      {
        queryName: "getFinalAccessionReport",
        relativeUrl: "/final-reported-accessions/get-far",
        limitData,
      },
      "To fetch final accession report.",
      variables
    );
  }

  async archiveCustomFAR(params) {
    const { variables, limitData } = params;
    return await storeActions.mutation(
      {
        relativeUrl: "/final-reported-accessions/archive-custom-far",
        queryName: "archiveCustomFinalAccessionReport",
        shouldNotReturnId: true,
        limitData: limitData || null,
      },
      "To update custom final accession report config.",
      variables
    );
  }

  async searchValidFARRecipients(recipient = null) {
    return await storeActions.query(
      {
        queryName: "validFinalAccessionReportRecipients",
        relativeUrl: "/final-reported-accessions/valid-far-recipients",
        noQueryString: true,
        recipient: recipient || null,
      },
      "To fetch valid final accession report recipients."
    );
  }

  async sendFinalAccessionReportEmail(params) {
    const { variables, limitData } = params;
    return await storeActions.mutation(
      {
        relativeUrl: "/final-reported-accessions/send-far-email",
        queryName: "sendFinalAccessionReportEmail",
        limitData: limitData || null,
        shouldNotReturnId: true,
      },
      "To send final accession report email to recipients.",
      variables
    );
  }
}
