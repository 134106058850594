import Actions from "modules/actions";

import FinalAccessionReportActions from "store/actions/finalAccessionReport";

const finalAccessionReportActions = new FinalAccessionReportActions();
export default class OrderEntryActions extends Actions {
  async getFinalAccessionReportConfig(type, limitData = null) {
    if (type.toUpperCase() == "MONTHLY")
      return await finalAccessionReportActions.getMonthlyFARC(limitData);

    return await finalAccessionReportActions.getCustomFARC(limitData);
  }
  async updateFinalAccessionReportConfig(type, params) {
    if (type.toUpperCase() == "MONTHLY")
      return await finalAccessionReportActions.updateMonthlyFARC(params);

    return await finalAccessionReportActions.updateCustomFARC(params);
  }
  async generateCustomFinalAccessionReport(params) {
    return await finalAccessionReportActions.generateCustomFinalAccessionReport(
      params
    );
  }

  async getFinalAccessionReport(params) {
    return await finalAccessionReportActions.getFinalAccessionReport(params);
  }

  async archiveCustomFAR(params) {
    return await finalAccessionReportActions.archiveCustomFAR(params);
  }

  async searchValidFinalAccessionReportRecipients(recipient) {
    return await finalAccessionReportActions.searchValidFARRecipients(
      recipient
    );
  }

  async sendFinalAccessionReportEmail(params) {
    return await finalAccessionReportActions.sendFinalAccessionReportEmail(
      params
    );
  }
}
