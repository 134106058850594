import Vue from "vue";
import axios from "axios";
import moment from "moment";

import Actions from "modules/actions/final-accession-report-actions";

import FinalAccessionReport from "store/models/FinalAccessionReport";

import QueryCriteria from "../components/QueryCriteria";
import SearchFilter from "components/SearchFilter";
import RecipientsModal from "modules/analytics/modals/RecipientsModal";

export default {
  name: "FinalReportedAccessionsMonthEndReport",
  components: {
    QueryCriteria,
    RecipientsModal,
  },
  data() {
    return {
      actions: new Actions(),
      filter: "",
      searchFilter: null,
      totalCount: 0,
      isLoading: {
        report: false,
        dataTable: false,
        refresh: false,
      },
      initialDataLoading: false,
      modalVisibility: {
        fieldsToDisplay: false,
        report: false,
        downloadReport: false,
        sendReport: false,
      },
      reportData: "",
      sendReportId: null,
      yearsToDisplay: {
        items: [],
        selected: null,
      },
      refreshObj: {
        instance: null,
        message: "",
        timeout: 10,
      },
      items: [],
      columns: [
        {
          name: "reportId",
          align: "left",
          text: "Report ID",
          field: "reportId",
        },
        {
          name: "fileName",
          align: "left",
          text: "Filename",
          field: "fileName",
        },
        {
          name: "dateGenerated",
          align: "left",
          text: "Date Generated",
          field: "dateGenerated",
        },
        {
          name: "createdBy",
          align: "left",
          text: "Created By",
          field: "createdBy",
        },
        {
          name: "status",
          align: "center",
          text: "Status",
          field: "status",
          isStatusColumn: true,
        },
        {
          name: "action",
          text: "Action",
          field: "action",
          align: "center",
        },
      ],
    };
  },
  computed: {
    disableRefreshBtn() {
      return this.isLoading.dataTable || this.isLoading.refresh;
    },
  },
  methods: {
    async fetch() {
      try {
        this.isLoading.dataTable = true;

        await this.searchFilter.filterSearchModel(null, null);
      } catch (e) {
        this.showNotifyMessage({
          message: "Unable to fetch data.",
          type: "danger",
        });
      } finally {
        this.isLoading.dataTable = false;
      }
    },
    async saveConfiguration(e) {
      const { configuration, reportOrientation } = e;
      try {
        let finalAccessionReportConfig = configuration.reduce(
          (a, b) => ({ ...a, [b.name]: b.selected }),
          {}
        );

        finalAccessionReportConfig["isPortrait"] =
          reportOrientation.toUpperCase() == "PORTRAIT" ? true : false;

        const result = await this.actions.updateFinalAccessionReportConfig(
          "monthly",
          {
            variables: {
              config: {
                type: "FinalAccessionReportConfigInput",
                value: finalAccessionReportConfig,
                unincludeToFields: true,
              },
            },
            limitData: ["success", "entityId", "errors"],
          }
        );

        if (result.success) {
          this.showNotifyMessage({
            message: "Configuration saved.",
            type: "success",
          });
        } else {
          this.showNotifyMessage({
            message:
              "A problem has occured while saving final accession report config data.",
            type: "danger",
          });
        }
      } catch (err) {
        this.showNotifyMessage({
          message:
            "A problem has occured while saving final accession report config data.",
          type: "danger",
        });
      } finally {
        this.modalVisibility.fieldsToDisplay = false;
      }
    },
    async viewReport(id) {
      try {
        this.isLoading.report = true;
        this.modalVisibility.report = true;

        const report = await this.actions.getFinalAccessionReport({
          variables: {
            id,
          },
          limitData: ["previewUrl"],
        });

        if (report && report.previewUrl) {
          const result = await axios.get(report.previewUrl, {
            responseType: "blob",
          });

          if (result.status == 200) {
            const blob = new Blob([result.data], { type: "application/pdf" });
            this.reportData = window.URL.createObjectURL(blob);
          }
        } else {
          this.reportData = "";

          this.showNotifyMessage({
            message:
              "A problem has occured while fetching final accession report data.",
            type: "danger",
          });
        }
      } catch (err) {
        this.showNotifyMessage({
          message:
            "A problem has occured while fetching final accession report data.",
          type: "danger",
        });
      } finally {
        this.isLoading.report = false;
      }
    },
    sendReport(id) {
      this.modalVisibility.sendReport = true;
      this.sendReportId = id;
    },
    async doSendReport(e) {
      try {
        const { selected, reportId } = e;

        const result = await this.actions.sendFinalAccessionReportEmail({
          variables: {
            id: {
              type: "UUID!",
              value: reportId,
              unincludeToFields: true,
            },
            recipients: {
              type: "[String!]",
              value: selected,
              unincludeToFields: true,
            },
          },
          limitData: ["success"],
        });

        if (result && result.success) {
          this.showNotifyMessage({
            message: "Report sent to Recipient(s)",
            type: "success",
          });
        } else {
          this.showNotifyMessage({
            message:
              "A problem has occured while sending report to recipient(s).",
            type: "danger",
          });
        }
      } catch (err) {
        this.showNotifyMessage({
          message:
            "A problem has occured while sending report to recipient(s).",
          type: "danger",
        });
      } finally {
        this.modalVisibility.sendReport = false;
        this.sendReportId = null;
      }
    },
    async downloadReport(id) {
      try {
        this.modalVisibility.downloadReport = true;

        const report = await this.actions.getFinalAccessionReport({
          variables: {
            id,
          },
          limitData: ["downloadUrl"],
        });

        if (report && report.downloadUrl) {
          window.open(report.downloadUrl);
        } else {
          this.showNotifyMessage({
            message:
              "A problem has occured while downloading final accession report data.",
            type: "danger",
          });
        }
      } catch (err) {
        this.showNotifyMessage({
          message:
            "A problem has occured while downloading final accession report data.",
          type: "danger",
        });
      } finally {
        this.modalVisibility.downloadReport = false;
      }
    },
    archiveReport() {
      console.log("archive report");
    },
    async changeYearToDisplay() {
      this.searchFilter.dataFields[0].value = this.yearsToDisplay.selected;

      await this.fetch();
    },
    async refresh() {
      this.isLoading.refresh = true;

      await this.fetch();

      this.refreshObj.message = `Refresh available in ${this.refreshObj.timeout} secs`;

      let theCounter = this.refreshObj.timeout;
      this.refreshObj.instance = setInterval(() => {
        theCounter--;

        this.refreshObj.message = `Refresh available in ${theCounter} sec${
          theCounter != 1 ? "s" : ""
        }`;

        if (theCounter == 0) {
          this.isLoading.refresh = false;
          this.refreshObj.message = "";
          clearInterval(this.refreshObj.instance);
        }
      }, 1000);
    },
  },
  async mounted() {
    this.$nextTick(async () => {
      this.initialDataLoading = true;

      let theSearchFilter = Vue.extend(SearchFilter);

      // get current year
      const currentYear = moment().year();
      let theYear = currentYear;

      // add 1 past year
      this.yearsToDisplay.items.push((currentYear - 1).toString());
      for (var i = 0; i < 3; i++) {
        if (i == 0) this.yearsToDisplay.selected = theYear.toString();

        this.yearsToDisplay.items.push(theYear.toString());

        theYear++;
      }

      this.searchFilter = new theSearchFilter({
        propsData: {
          modelInstance: FinalAccessionReport,
          limitData: [
            "reportId",
            "fileName",
            "dateGenerated",
            "createdBy",
            "retryCount",
            this.buildSubQuery("status", ["enum", "label"], false),
          ],
          relativeUrl: "/final-reported-accessions/get-all",
          queryName: "monthlyFinalAccessionReports",
        },
        parent: this.$refs.container.__vue__,
      });

      this.searchFilter.$on("searchResults", (data) => {
        const statuses = ["PENDING", "IN_PROGRESS", "FAILED"];

        if (data.length) {
          this.items = data.map((item) => {
            const {
              id,
              reportId,
              fileName,
              dateGenerated,
              createdBy,
              status,
              retryCount,
            } = item;

            return {
              id,
              reportId,
              fileName,
              dateGenerated:
                dateGenerated && dateGenerated.toUpperCase() != "NONE"
                  ? this.$options.filters.getTimeDate(
                      dateGenerated,
                      "MM/DD/YYYY hh:mm A"
                    ).zone
                  : "-",
              createdBy,
              status:
                status.label.toUpperCase() == "PENDING"
                  ? "scheduled"
                  : status.label,
              statusCount: retryCount,
              disableEdit: true,
              additionalMoreCommands: [
                {
                  label: "View",
                  action: (id) => this.viewReport(id),
                  isDisabled: statuses.includes(status.enum),
                },
                {
                  label: "Send Report",
                  action: (id) => this.sendReport(id),
                  isDisabled: statuses.includes(status.enum),
                },
                {
                  label: "Download Report",
                  action: (id) => this.downloadReport(id),
                  isDisabled: statuses.includes(status.enum),
                },
              ],
            };
          });
        } else {
          this.items = [];
        }
      });

      this.searchFilter.dataFields.push({
        name: "yearToDisplay",
        type: "text",
        value: this.yearsToDisplay.selected,
      });

      await this.fetch();
      this.initialDataLoading = false;
    });
  },
};
