import FieldsToDisplay from "modules/analytics//modals/FieldsToDisplayModal";

export default {
  name: "QueryCriteria",
  props: {
    hasRunSchedule: Boolean,
    hasDateRange: Boolean,
    hasGenerateBtn: Boolean,
    saveBtnLabel: String,
    type: String,
    displayFields: Boolean,
    isLoadingGenerateBtn: Boolean,
    label: String,
  },
  components: {
    FieldsToDisplay,
  },
  data() {
    return {
      range: {
        reportName: "Final Report Accessions",
        runSchedule: "Last Day of Month, US PDT",
        date: {
          dateMenu1: false,
          dateMenu2: false,
          dateInput1: "",
          dateInput2: "",
          from: "",
          to: "",
        },
      },
      rules: {
        required: (val) => {
          if (typeof val === "object" && val !== null)
            return (val.id && val.id !== null) || "This field is required.";

          return (
            (val !== null && val.length !== 0) || "This field is required."
          );
        },
        validDate: (val) => this.isValidDate(val) || "Invalid date format.",
        validDateTo: (val, theDateFrom) => {
          const dateTo = new Date(val);
          const dateFrom = new Date(theDateFrom);

          if (theDateFrom == null || !theDateFrom.length) return true;

          return (
            dateTo >= dateFrom ||
            "End Date must not be less than the Start Date."
          );
        },
      },
    };
  },
  computed: {
    dynamicColumn() {
      if (
        (!this.hasDateRange && !this.hasRunSchedule) ||
        !this.hasDateRange ||
        (this.hasDateRange && !this.hasRunSchedule)
      )
        return 3;

      return 2;
    },
    dynamicFTDBColumn() {
      if (this.hasDateRange && this.hasRunSchedule) return 2;

      return "";
    },
    generateBtnDisabled() {
      return (
        this.range.date.from == null ||
        !this.range.date.from.length ||
        this.range.date.to == null ||
        !this.range.date.to.length ||
        !this.$refs.dateFrom ||
        !this.$refs.dateTo ||
        !this.$refs.dateFrom.valid ||
        !this.$refs.dateTo.valid
      );
    },
    __FTDModalVisibility: {
      get() {
        return this.displayFields;
      },
      set(value) {
        return this.$emit("update:displayFields", value);
      },
    },
    __LoadingGenerateBtn: {
      get() {
        return this.isLoadingGenerateBtn;
      },
      set(value) {
        this.$emit("update:isLoadingGenerateBtn", value);
      },
    },
  },
  methods: {
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    renderDates(date, allowFutureDates = false) {
      if (allowFutureDates) return true;
      return new Date(date) <= new Date();
    },
    closeDateModal(type) {
      if (type.toUpperCase() === "FROM") {
        this.range.date.from = this.formatDate(this.range.date.dateInput1);
      } else {
        this.range.date.to = this.formatDate(this.range.date.dateInput2);
      }

      this.$refs.menu1.save();
      this.$refs.menu2.save();
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    saveConfiguration(e) {
      this.$emit("saveConfiguration", e);
    },
    async generateReport() {
      const validate = await this.$refs.queryCriteriaForm.validateAsync();

      if (!validate) return;

      this.$emit("generateReport", {
        from: this.range.date.from,
        to: this.range.date.to,
      });
    },
  },
};
