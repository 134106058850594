import CustomReport from "./CustomReport";
import MonthEndReport from "./MonthEndReport";

export default {
  name: "FinalReportedAccessions",
  components: {
    CustomReport,
    MonthEndReport,
  },
  data() {
    return {
      tab: "",
    };
  },
};
