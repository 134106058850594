import Vue from "vue";
import axios from "axios";
import moment from "moment";

import Actions from "modules/actions/final-accession-report-actions";

import FinalAccessionReport from "store/models/FinalAccessionReport";

import QueryCriteria from "../components/QueryCriteria";
import SearchFilter from "components/SearchFilter";
import RecipientsModal from "modules/analytics/modals/RecipientsModal";

export default {
  name: "FinalReportedAccessionsCustomReport",
  components: {
    QueryCriteria,
    RecipientsModal,
  },
  data() {
    return {
      actions: new Actions(),
      filter: "",
      searchFilter: null,
      totalCount: 0,
      isLoading: {
        report: false,
        dataTable: false,
        searchFilter: false,
        archive: false,
        generate: false,
        refresh: false,
      },
      initialDataLoading: false,
      modalVisibility: {
        fieldsToDisplay: false,
        report: false,
        prompt: false,
        downloadReport: false,
        sendReport: false,
      },
      reportData: "",
      sendReportId: null,
      yearsToDisplay: {
        items: [],
        selected: null,
      },
      refreshObj: {
        instance: null,
        message: "",
        timeout: 10,
      },
      promptMessage:
        "Are you sure you want to archive the selected final accession report(s)?",
      items: [],
      selected: [],
      columns: [
        {
          name: "reportId",
          align: "left",
          text: "Report ID",
          field: "reportId",
          sortable: true,
        },
        {
          name: "fileName",
          align: "left",
          text: "Filename",
          field: "fileName",
        },
        {
          name: "dateOfCoverage",
          align: "left",
          text: "Date of Coverage",
          field: "dateCoverage",
        },
        {
          name: "dateRequested",
          align: "left",
          text: "Date Requested",
          field: "dateRequested",
        },
        {
          name: "dateGenerated",
          align: "left",
          text: "Date Generated",
          field: "dateGenerated",
        },
        {
          name: "createdBy",
          align: "left",
          text: "Created By",
          field: "createdBy",
        },
        {
          name: "status",
          align: "center",
          text: "Status",
          field: "status",
          isStatusColumn: true,
        },
        {
          name: "action",
          text: "Action",
          field: "action",
          align: "center",
        },
      ],
      selectAction: [
        {
          label: "Archive",
          action: () => {
            return;
          },
        },
      ],
    };
  },
  computed: {
    disableRefreshBtn() {
      return this.isLoading.dataTable || this.isLoading.refresh;
    },
  },
  methods: {
    async fetch() {
      try {
        this.isLoading.dataTable = true;
        this.selected = [];

        this.$refs.dataTable.resetDataTableOptions();
        await this.searchFilter.filterSearchModel(10, null);
      } catch (e) {
        this.showNotifyMessage({
          message: "Unable to fetch data.",
          type: "danger",
        });
      } finally {
        this.isLoading.dataTable = false;
      }
    },
    async saveConfiguration(e) {
      const { configuration, reportOrientation } = e;
      try {
        let finalAccessionReportConfig = configuration.reduce(
          (a, b) => ({ ...a, [b.name]: b.selected }),
          {}
        );

        finalAccessionReportConfig["isPortrait"] =
          reportOrientation.toUpperCase() == "PORTRAIT" ? true : false;

        const result = await this.actions.updateFinalAccessionReportConfig(
          "custom",
          {
            variables: {
              config: {
                type: "FinalAccessionReportConfigInput",
                value: finalAccessionReportConfig,
                unincludeToFields: true,
              },
            },
            limitData: ["success", "entityId", "errors"],
          }
        );

        if (result.success) {
          this.showNotifyMessage({
            message: "Configuration saved.",
            type: "success",
          });
        } else {
          this.showNotifyMessage({
            message:
              "A problem has occured while saving final accession report config data.",
            type: "danger",
          });
        }
      } catch (err) {
        this.showNotifyMessage({
          message:
            "A problem has occured while saving final accession report config data.",
          type: "danger",
        });
      } finally {
        this.modalVisibility.fieldsToDisplay = false;
      }
    },
    async viewReport(id) {
      try {
        this.isLoading.report = true;
        this.modalVisibility.report = true;

        const report = await this.actions.getFinalAccessionReport({
          variables: {
            id,
          },
          limitData: ["previewUrl"],
        });

        if (report && report.previewUrl) {
          const result = await axios.get(report.previewUrl, {
            responseType: "blob",
          });

          if (result.status == 200) {
            const blob = new Blob([result.data], { type: "application/pdf" });
            this.reportData = window.URL.createObjectURL(blob);
          }
        } else {
          this.reportData = "";

          this.showNotifyMessage({
            message:
              "A problem has occured while fetching final accession report data.",
            type: "danger",
          });
        }
      } catch (err) {
        this.showNotifyMessage({
          message:
            "A problem has occured while fetching final accession report data.",
          type: "danger",
        });
      } finally {
        this.isLoading.report = false;
      }
    },
    sendReport(id) {
      this.modalVisibility.sendReport = true;
      this.sendReportId = id;
    },
    async doSendReport(e) {
      try {
        const { selected, reportId } = e;

        const result = await this.actions.sendFinalAccessionReportEmail({
          variables: {
            id: {
              type: "UUID!",
              value: reportId,
              unincludeToFields: true,
            },
            recipients: {
              type: "[String!]",
              value: selected,
              unincludeToFields: true,
            },
          },
          limitData: ["success"],
        });

        if (result && result.success) {
          this.showNotifyMessage({
            message: "Report sent to Recipient(s)",
            type: "success",
          });
        } else {
          this.showNotifyMessage({
            message:
              "A problem has occured while sending report to recipient(s).",
            type: "danger",
          });
        }
      } catch (err) {
        this.showNotifyMessage({
          message:
            "A problem has occured while sending report to recipient(s).",
          type: "danger",
        });
      } finally {
        this.modalVisibility.sendReport = false;
        this.sendReportId = null;
      }
    },
    async downloadReport(id) {
      try {
        this.modalVisibility.downloadReport = true;

        const report = await this.actions.getFinalAccessionReport({
          variables: {
            id,
          },
          limitData: ["downloadUrl"],
        });

        if (report && report.downloadUrl) {
          window.open(report.downloadUrl);
        } else {
          this.showNotifyMessage({
            message:
              "A problem has occured while downloading final accession report data.",
            type: "danger",
          });
        }
      } catch (err) {
        this.showNotifyMessage({
          message:
            "A problem has occured while downloading final accession report data.",
          type: "danger",
        });
      } finally {
        this.modalVisibility.downloadReport = false;
      }
    },
    archiveReport(id) {
      this.archiveReports(id);
    },
    async changeYearToDisplay() {
      this.searchFilter.dataFields[0].value = this.yearsToDisplay.selected;

      await this.fetch();
    },
    selectDeselect(data) {
      this.selected = data;
    },
    archiveReports(id = null) {
      this.modalVisibility.prompt = true;
      if (id) this.selected = [{ id }];
    },
    async doArchiveReports() {
      try {
        this.isLoading.archive = true;

        if (this.selected.length) {
          for (let item of this.selected) {
            await this.actions.archiveCustomFAR({
              variables: {
                id: {
                  type: "UUID!",
                  value: item.id,
                  unincludeToFields: true,
                },
              },
              limitData: ["success", "errors"],
            });
          }

          this.showNotifyMessage({
            message: "Archive Completed.",
            type: "success",
          });

          this.modalVisibility.prompt = false;
          this.isLoading.archive = false;
          this.selected = [];
          await this.fetch();
        }
      } catch (err) {
        this.modalVisibility.prompt = false;
        this.isLoading.archive = false;

        this.showNotifyMessage({
          message:
            "A problem has occured while archiving final accession report data.",
          type: "danger",
        });
      }
    },
    async generateReport({ from, to }) {
      try {
        this.isLoading.generate = true;
        const result = await this.actions.generateCustomFinalAccessionReport({
          input: {
            type: "FinalAccessionReportInput",
            value: {
              startDate: this.$options.filters.getTimeDate(
                from,
                "YYYY-MM-DD HH:mm:ss",
                false,
                null,
                this.getTimezoneOffset({
                  date: from,
                })
              ).utc,
              endDate: this.$options.filters.getTimeDate(
                to,
                "YYYY-MM-DD HH:mm:ss",
                false,
                null,
                {
                  days: 1,
                  ...this.getTimezoneOffset({
                    date: to,
                    minusSeconds: 1,
                  }),
                }
              ).utc,
            },
            unincludeToFields: true,
          },
          limitData: ["success"],
        });

        if (result) {
          this.showNotifyMessage({
            message: "Custom Final Accession Report generation started.",
            type: "info",
          });

          this.isLoading.generate = false;

          await this.fetch();
        }
      } catch (err) {
        if (err.graphQLErrors && err.graphQLErrors.length) {
          this.showNotifyMessage({
            message:
              "Already have an existing in-pending/in-progress report generation.",
            type: "danger",
          });
        } else {
          this.showNotifyMessage({
            message:
              "A problem has occured while generating custom final accession report.",
            type: "danger",
          });
        }
      } finally {
        this.isLoading.generate = false;
      }
    },
    async refresh() {
      this.isLoading.refresh = true;

      await this.fetch();

      this.refreshObj.message = `Refresh available in ${this.refreshObj.timeout} secs`;

      let theCounter = this.refreshObj.timeout;
      this.refreshObj.instance = setInterval(() => {
        theCounter--;

        this.refreshObj.message = `Refresh available in ${theCounter} sec${
          theCounter != 1 ? "s" : ""
        }`;

        if (theCounter == 0) {
          this.isLoading.refresh = false;
          this.refreshObj.message = "";
          clearInterval(this.refreshObj.instance);
        }
      }, 1000);
    },
  },
  async mounted() {
    this.$nextTick(async () => {
      this.initialDataLoading = true;

      // extend search filter as component
      let theSearchFilter = Vue.extend(SearchFilter);

      // get current year
      const currentYear = moment().year();

      // add 1 past year
      this.yearsToDisplay.items.push((currentYear - 1).toString());
      let theYear = currentYear;
      for (var i = 0; i < 3; i++) {
        if (i == 0) this.yearsToDisplay.selected = theYear.toString();

        this.yearsToDisplay.items.push(theYear.toString());

        theYear++;
      }

      // create search filter component instance
      this.searchFilter = new theSearchFilter({
        propsData: {
          modelInstance: FinalAccessionReport,
          limitData: [
            "reportId",
            "fileName",
            "dateGenerated",
            "dateRequested",
            "createdBy",
            this.buildSubQuery("status", ["enum", "label"], false),
            "totalCount",
            "errorDescription",
            "errorDetails",
            "retryCount",
            "startDate",
            "endDate",
          ],
          relativeUrl: "/final-reported-accessions/get-all",
          queryName: "customFinalAccessionReports",
          isLoading: this.isLoading.searchFilter,
        },
        parent: this.$refs.container.__vue__,
      });

      // listen to search result emitted
      this.searchFilter.$on("searchResults", (data) => {
        const statuses = ["PENDING", "IN_PROGRESS", "FAILED"];

        if (data.length) {
          this.items = data.map((item, index) => {
            if (index == 0) this.totalCount = item.totalCount;

            const {
              id,
              reportId,
              fileName,
              dateGenerated,
              dateRequested,
              createdBy,
              status,
              retryCount,
              startDate,
              endDate,
            } = item;

            return {
              id,
              reportId,
              fileName,
              dateGenerated:
                dateGenerated && dateGenerated.toUpperCase() != "NONE"
                  ? this.$options.filters.getTimeDate(
                      dateGenerated,
                      "MM/DD/YYYY hh:mm A"
                    ).zone
                  : "-",
              dateCoverage: `${
                this.$options.filters.getTimeDate(startDate, "MM/DD/YYYY").zone
              } - ${
                this.$options.filters.getTimeDate(endDate, "MM/DD/YYYY").zone
              }`,
              dateRequested: dateRequested
                ? this.$options.filters.getTimeDate(
                    dateRequested,
                    "MM/DD/YYYY hh:mm A"
                  ).zone
                : "-",
              createdBy,
              status:
                status.label.toUpperCase() == "PENDING"
                  ? "scheduled"
                  : status.label,
              statusCount: retryCount,
              disableEdit: true,
              additionalMoreCommands: [
                {
                  label: "View",
                  action: (id) => this.viewReport(id),
                  isDisabled: statuses.includes(status.enum),
                },
                {
                  label: "Send Report",
                  action: (id) => this.sendReport(id),
                  isDisabled: statuses.includes(status.enum),
                },
                {
                  label: "Download Report",
                  action: (id) => this.downloadReport(id),
                  isDisabled: statuses.includes(status.enum),
                },
                {
                  label: "Archive Report",
                  action: (id) => this.archiveReport(id),
                  isDisabled:
                    status.enum == "PENDING" || status.enum == "IN_PROGRESS",
                },
              ],
            };
          });
        } else {
          this.items = [];
          this.totalCount = 0;
        }
      });

      // listen to loading emitted
      this.searchFilter.$on("loading", (bool) => {
        this.isLoading.dataTable = bool;
      });

      this.searchFilter.dataFields.push({
        name: "yearToDisplay",
        type: "text",
        value: this.yearsToDisplay.selected,
      });

      await this.fetch();

      this.initialDataLoading = false;
    });
  },
};
