import isEqual from "lodash/isEqual";

import Actions from "modules/actions/final-accession-report-actions";

export default {
  name: "FieldsToDisplayModal",
  props: {
    showModal: Boolean,
    saveBtnLabel: String,
    type: String,
  },
  data() {
    return {
      actions: new Actions(),
      fields: [],
      selectedFields: [],
      configFields: [],
      isLoading: false,
      isSaving: false,
      prompt: {
        visibility: false,
        message: "",
      },
      columns: [
        {
          name: "fieldName",
          text: "Field Name",
          value: "label",
          class: "subtitle-2",
          sortable: false,
        },
      ],
      reportOrientation: {
        items: ["Landscape", "Portrait"],
        original: "",
        selected: "Landscape",
      },
    };
  },
  computed: {
    __ShowModal: {
      get() {
        return this.showModal;
      },
      set(value) {
        this.$emit("update:showModal", value);
      },
    },
    saveBtnDisabled() {
      return this.isLoading || this.isSaving || !this.selectedFields.length;
    },
  },
  methods: {
    closeModal() {
      if (!isEqual(this.fields, this.configFields)) {
        this.prompt.message =
          "You haven’t saved your entries. Are you sure you want to discard them?";
        this.prompt.visibility = true;
      } else {
        this.doCloseModal();
      }
    },
    doCloseModal() {
      this.$emit("update:showModal", false);
    },
    reset() {
      this.selectedFields = JSON.parse(
        JSON.stringify(this.configFields.filter((item) => item.selected))
      );

      this.reportOrientation.selected = this.reportOrientation.original;
    },
    itemSelectDeselect(e) {
      const { value, item } = e;

      const findIndex = this.fields.findIndex(
        (fieldItem) => fieldItem.name == item.name
      );

      if (findIndex > -1) this.fields[findIndex].selected = value;
    },
    selectDeselectAll({ value }) {
      if (value) return this.fields.map((item) => (item.selected = true));

      return this.fields.forEach((item) => (item.selected = false));
    },
    save() {
      this.isSaving = true;
      this.$emit("saveConfiguration", {
        configuration: this.fields,
        reportOrientation: this.reportOrientation.selected,
      });
    },
    sanitizeFieldName(item) {
      const labelFields = {
        orderNumber: "Order Number",
        clientId: "Client (Customer) ID",
        clientName: "Client (Customer) Name",
        accessionNumber: "Accession / TRF Number",
        patientLastName: "Patient Last Name",
        patientFirstName: "Patient First Name",
        dateOfService: "Date of Service",
        dateReceivedInLab: "Date Received in Lab",
        dateResultsFinalized: "Date Results Finalized",
        orderingPhysicianNPI: "Ordering Physician NPI #",
        orderingPhysicianLastName: "Ordering Physician Last Name",
        orderingPhysicianFirstName: "Ordering Physician First Name",
        testCode: "Test ID (Code)",
        testName: "Test ID (Name)",
        orderStatus: "Order Status",
      };

      return labelFields[item];
    },
  },
  async created() {
    try {
      this.isLoading = true;

      const result = await this.actions.getFinalAccessionReportConfig(
        this.type || "custom"
      );

      if (result) {
        this.fields = Object.keys(result)
          .filter((item) => item != "__typename" && item != "isPortrait")
          .map((item) => {
            const itemObj = {
              name: item,
              label: this.sanitizeFieldName(item),
              selected: result[item],
            };

            if (result[item]) this.selectedFields.push(itemObj);

            return itemObj;
          });

        // set report orientation
        this.reportOrientation.selected = result.isPortrait
          ? "Portrait"
          : "Landscape";

        this.reportOrientation.original = this.reportOrientation.selected;

        this.configFields = JSON.parse(JSON.stringify(this.fields));
      }
    } catch (err) {
      this.showNotifyMessage({
        type: "danger",
        message: "A problem has occured while fetching data.",
      });
    } finally {
      this.isLoading = false;
    }
  },
};
