import { uuid } from "vue-uuid";

import Actions from "modules/actions/final-accession-report-actions";

const actions = new Actions();
export default {
  name: "RecipientsModal",
  props: {
    visibility: Boolean,
    reportId: String,
  },
  data() {
    return {
      recipients: {
        items: [],
        selected: [],
        searchValue: "",
      },
      isLoading: false,
      isSendingReport: false,
      rules: {
        validEmail: (val) => {
          const unvalidatedRecipients = val.filter(
            (item) => !item.value.isFromValidRecipients
          );

          if (unvalidatedRecipients.length)
            for (let email of unvalidatedRecipients) {
              if (typeof this.validateEmail(email.value.name) == "string")
                return "Invalid email.";
            }

          return true;
        },
        validEmailDomain: (val) => {
          const unvalidatedRecipients = val.filter(
            (item) => !item.value.isFromValidRecipients
          );

          if (unvalidatedRecipients.length)
            for (let email of unvalidatedRecipients) {
              if (!this.checkDomainValidity(email.value.name))
                return "Invalid email.";
            }

          return true;
        },
      },
    };
  },
  computed: {
    __Visibility: {
      get() {
        return this.visibility;
      },
      set(value) {
        this.$emit("update:visibility", value);
      },
    },
    disableSendReportBtn() {
      return (
        !this.recipients.selected.length ||
        this.isSendingReport ||
        !this.$refs.autocomplete.valid
      );
    },
  },
  methods: {
    async getValidFARRecipients(recipient = null) {
      try {
        this.isLoading = true;

        this.recipients.items = [
          { disabled: true, text: "Fetching Valid Recipients..." },
        ];

        const result = await actions.searchValidFinalAccessionReportRecipients(
          recipient
        );

        if (result.length) {
          this.recipients.items = result.map((item) => {
            return {
              text: item,
              value: {
                id: uuid.v1(),
                name: item,
                isFromValidRecipients: true,
              },
            };
          });
        } else {
          this.recipients.items = [];
        }
      } catch (err) {
        this.showNotifyMessage({
          message: "A problem has occured while fetching data.",
          type: "danger",
        });
      } finally {
        this.isLoading = false;
      }
    },
    onFocus() {
      if (!this.recipients.selected.length) this.getValidFARRecipients();
    },
    sendReportFromSelected() {
      this.isSendingReport = true;

      this.$emit("sendReportFromSelected", {
        reportId: this.reportId,
        selected: this.recipients.selected.map((item) => item.value.name),
      });
    },
    addEmail(value) {
      this.recipients.items.push({
        text: value,
        value: {
          id: uuid.v1(),
          name: value,
          isFromValidRecipients: false,
        },
      });
    },
    async removeEmail(uuid) {
      const findIndex = this.recipients.selected.findIndex(
        (item) => item.value.id == uuid
      );

      if (findIndex > -1) {
        this.recipients.selected.splice(findIndex, 1);
      } else {
        this.showNotifyMessage({
          message: "A problem has occured while removing email recipient.",
          type: "danger",
        });
      }

      await this.$refs.autocomplete.validateAsync();
    },
    checkDomainValidity(email) {
      return /^[A-Za-z0-9._%+-]+@venn.bio|@intervenn.com|@vennbiosciences.com/i.test(
        email
      );
    },
    isValidChip(item) {
      if (item.value.isFromValidRecipients) return true;

      return (
        this.validateEmail(item.value.name) &&
        this.checkDomainValidity(item.value.name)
      );
    },
  },
  async mounted() {
    await this.$refs.autocomplete.validateAsync();
  },
};
